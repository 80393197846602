<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <router-link :to="'/administrator/create-company'" class="btn btn-primary" type="button">
                                    {{ translations?.create_new }}
                                </router-link>
                            </div>
                            <div class="expand-ctl">
                                <a class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseProject-ctl" aria-expanded="false" aria-controls="collapseProject-ctl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div class="collapse mt-3" id="collapseProject-ctl">
                            <div class="row">
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <select class="form-select" aria-label="Project-Title">
                                        <option selected>{{ translations?.all }}</option>
                                        <option value="1">{{ translations?.wi_work_instruction }}</option>
                                        <option value="2">{{ translations?.regulation_reg }}</option>
                                        <option value="3">{{ translations?.standard_operating_procedure_sop }}</option>
                                        <option value="3">{{ translations?.form }}</option>
                                    </select>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <form action="">
                                        <div class="input-group search-box">
                                            <input type="text" class="form-control" :placeholder="translations?.search" name="search" value="">
                                            <button class="btn btn-outline-secondary" type="button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <data-table :translations="translations" id="example-data-table"
                        :options="options"
                        :data-set="data.dataList"
                        :page-size="data.pageSize"
                        @change-action="changeAction"
                        @change-status="changeStatus"
                        @pagination="pagination"
                        @sorting="sorting"
                        @limit-change="limitChange"/>

                </div>
            </div>
        </div>
    </section>

    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="editCompanyModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ translations?.company_update }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                    <update-company-form
                        :form-data="data"
                        @form-action="formAction"
                        @form-file="formFile"
                        :image-url="data.imageUrl"
                    >
                    </update-company-form>
            </div>
        </div>
    </div>

</template>

<script>
    import DataTable from "@/common/components/datatable";
    import { onMounted, computed } from 'vue'
    import HelperFunction  from "@/common/helpers";
    import UpdateCompanyForm from "@/views/auth/administrator/company/UpdateCompanyForm";
    import {useStore} from "vuex";
    import { useRouter} from "vue-router";

    export default {
        name: "Company",
        components: {DataTable, UpdateCompanyForm},
        setup() {
            const { getAllData, data, editFormData, deleteListData, storeFormData, getFormFile } = HelperFunction();
            let setParams = {}
            const store = useStore()
            const router= useRouter()
            const company = store.getters.getSelectedBoard
            data.superAdmin = store.getters.getProfile.superAdmin
            data.setURL = vueConfig.Company.CompanyEndPoint

            const translations = computed(() => {
                return store.state.translations;  
            });

            onMounted(async () => {
                if (data.superAdmin) {
                    await getAllData(data.setURL, setParams)
                } else {
                    router.push(`/administrator/company-details/${company.id}`);
                }
            })
            
            // SET DATATABLE PROPS
            let options = {
                name: translations.value?.company_list || 'Company List',
                columns: [
                    {
                        title: translations.value?.logo || 'Logo',
                        type: 'image',
                        key: 'logo'
                    },
                    {
                        title: translations.value?.company_name || 'Company Name',
                        type: 'link',
                        key: 'company_name',
                        sort: true,
                        modifier: (obj, row) => {
                            return `/administrator/company-details/${row.id}`;
                        }
                    },
                    {
                        title: translations.value?.contact_person || 'Contact Person',
                        type: 'text',
                        key: 'name',
                        sort: true
                    },
                    {
                        title: translations.value?.email || 'Email',
                        type: 'text',
                        key: 'email',
                        sort: true
                    },
                    {
                        title: translations.value?.mobile || 'Mobile',
                        type: 'text',
                        key: 'mobile',
                        sort: true
                    },
                    {
                        title: translations.value?.subscription || 'Subscription',
                        type: 'custom',
                        key: 'subscription',
                        modifier: (obj, row) => {
                            return obj.type;
                        }
                    },
                    {
                        title: translations.value?.expire_on || 'Expire On',
                        type: 'date',
                        key: 'expired_at',
                        format: "MMMM Do YYYY"
                    },
                    {
                        title: translations.value?.status || 'Status',
                        type: 'radio',
                        key: 'status',
                        modifier: (value, row) => {
                            return value === 'Active' ? 1: 0;
                        }
                    },
                ],
                showAction: true,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: true
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: true
                    }
                ]
            }
            async function changeAction(action, row) {
                if (action.type === 'edit') {
                    await editFormData({url: `${data.setURL}/${row.id}`, modalId: 'showModal'}, false)
                }
                if (action.type === 'delete') {
                    await deleteListData(`${data.setURL}/${row.id}`, setParams)
                }
            }
            const changeStatus = (column, row) => {
                if (row.id) {
                    row._method = 'PUT'
                }
                storeFormData({url: `${vueConfig.Company.ChangeStatusEndPoint}/${row.id}`}, row)
            }
            const pagination = (page) => {
                setParams.page = page
                getAllData(data.setURL, setParams)
            }
            const limitChange = (limit) => {
                setParams.limit = limit
                delete setParams.page
                getAllData(data.setURL, setParams);
            }
            const sorting = (column) => {
                setParams.column = column.key
                setParams.sort = setParams.sort == 'asc' ? 'desc' : 'asc'
                getAllData(data.setURL, setParams);
            }
            function formAction(object, data) {
                if (data.id) {
                    data._method = 'PUT'
                    object.url = `${object.url}/${data.id}`
                }
                storeFormData(object, data, false, setParams)
            }
            function formFile(event, name) {
                getFormFile(event, name)
            }

            return {
                options, changeAction, changeStatus, pagination, sorting,
                getAllData, data, limitChange, formAction, formFile, translations
            }
        }
    }
</script>

<style scoped>

</style>
